<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<el-button size="medium" type="primary" v-print="'#printTable'" style="float: right; color: #fff">打印</el-button>
				<div class="title">{{ title }}</div>
			</div>
			<div class="viewbox" id="audit">
				<div class="result-tables-wrapper" id="printTable">
					<table v-for="(table, key) in tables" class="table__print" :key="key">
						<thead style="position: relative">
							<h4 class="table-title">{{ table.title }}</h4>
							<div v-if="table.orgRecords?.length && [11].includes(table.subType)" class="changeView">
								<el-button type="text" icon="el-icon-view" @click="dialogOrgVisible = true">调整预览</el-button>
							</div>
						</thead>
						<tbody v-if="table.orgRecords?.length">
							<tr class="table-col_4" v-for="(record, inx) in table.orgRecords" :key="record.id">
								<td class="property-value" style="text-align: left; padding-left: 10px; white-space: pre-line">
									{{ `${inx + 1}、${record.remark}` }}
								</td>
								<td v-if="[13].includes(table.subType)">
									<el-button type="text" icon="el-icon-view" @click="() => handleView(record)">调整预览</el-button>
								</td>
							</tr>
						</tbody>
						<tbody v-if="table.col === 4">
							<tr v-for="(row, i) in table.data" :key="i">
								<template v-for="(cell, ci) in row">
									<td class="property-name" :key="'name_' + ci">{{ cell.approvalPropertyName }}</td>
									<td style="width: 30%" :key="'value_' + ci">
										<div class="table-cell__content" v-if="cell.approvalPropertyType !== 7">{{ cell.approvalPropertyValue }}</div>
										<template v-else>
											<div
												class="docContainer"
												style="width: 100%"
												v-for="(file, index) in makeFileList(cell.approvalPropertyValue)"
												:key="index"
											>
												<div class="docContent">
													<img src="/static/images/fj.png" style="width: 40px; height: 40px" />
													<div style="width: auto">
														<!-- <el-tooltip :content="file.name"
                            placement="top">-->
														<span class="fileName">{{ file.name }}</span>
														<!-- </el-tooltip> -->
														<div style="text-align: left">
															<el-button
																v-show="isImageFileName(file.url)"
																type="text"
																class="media-print__hidden"
																@click="picPreview(file.url)"
																>预览</el-button
															>
															<a
																v-show="!isImageFileName(file.url)"
																class="media-print__hidden"
																style="color: #409eff; font-size: 14px; margin-right: 10px"
																target="_blank"
																:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
																>预览</a
															>
															<el-button type="text" class="media-print__hidden" @click="downLoadFile(file.url)"
																>下载</el-button
															>
														</div>
													</div>
												</div>
											</div>
											<!-- <a
                        :key="i"
                        :href="file.url||file.httpUrl"
                        style="display:block;"
                        v-for="(file, i) in makeFileList(cell.approvalPropertyValue)"
                      >
                        <i class="el-icon-picture"></i>{{file.name||file.fileName}}
                      </a>-->
										</template>
									</td>
								</template>
							</tr>
						</tbody>
						<tbody v-else-if="table.col === 2 && table.title == '审批记录'">
							<tr :key="i" v-for="(row, i) in table.data">
								<td class="property-name">{{ row.approvalStatusName }}</td>
								<td style="text-align: left; padding-left: 20px; width: 80%">
									<div class="table-cell__content">
										<p style="line-height: 30px; font-weight: bold">
											{{ row.userName }}
											{{ row.approvalStatus == 0 || row.approvalStatus == 6 || row.approvalStatus == 7 ? '' : row.time }}
										</p>
										<p style="line-height: 30px" v-if="row.approvalStatusName !== '发起'">
											审批意见: {{ row.approvalSuggestion }}
										</p>
									</div>
									<div class="docContainer" style="width: 100%" v-for="(file, index) in makeFileList(row.fileUrl)" :key="index">
										<div class="docContent">
											<img src="/static/images/fj.png" style="width: 40px; height: 40px" />
											<div style="width: auto">
												<!-- <el-tooltip :content="file.name"
                        placement="top">-->
												<span class="fileName">{{ file.name }}</span>
												<!-- </el-tooltip> -->
												<div style="text-align: left">
													<el-button
														v-show="isImageFileName(file.url)"
														type="text"
														class="media-print__hidden"
														@click="picPreview(file.url)"
														>预览</el-button
													>
													<a
														v-show="!isImageFileName(file.url)"
														class="media-print__hidden"
														style="color: #409eff; font-size: 14px; margin-right: 10px"
														target="_blank"
														:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
														>预览</a
													>
													<el-button type="text" class="media-print__hidden" @click="downLoadFile(file.url)"
														>下载</el-button
													>
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="table.col === 2 && table.title == '抄送记录'">
							<tr class="table-col_2">
								<td class="property-name">抄送人</td>
								<td class="property-value" style="text-align: left">
									<span v-for="(row, i) in table.data" :key="i" v-show="table.data.length > 0"
										>{{ row.userName }}{{ i == table.data.length - 1 ? '' : ',' }}</span
									>
									<span v-if="table.data.length <= 0">无记录</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div style="display: flex; justify-content: flex-end; margin-top: 30px">
						<P style="width: 200px">签字:</P>
						<p style="width: 200px">日期:</p>
					</div>
				</div>
				<!-- <div class="auditForm">
          <div>
            <div class="formTitle"><label>基本信息</label></div>
            <div class="formWrap">
              <div
                class="td"
                v-for="(item,index) in basicValueList"
                :key="index"
                style="display:inline-block;width:50%"
              >
                <div class="formRow leftLabel"><span>{{item.approvalPropertyName}}</span></div>
                <div class="formRow rightLabel"><span>{{item.approvalPropertyValue}}</span></div>
              </div>
              <div
                style="display:inline-block;width:50%;"
                v-if="!basicValueList.length % 2 == 0"
              >
                <div class="formRow leftLabel"><span></span></div>
                <div class="formRow rightLabel"><span></span></div>
              </div>
            </div>
          </div>
          <div>
            <div class="formTitle"><label>申请详情</label></div>
            <div class="formWrap">
              <div
                class="td"
                v-for="(item,index) in applyValueList"
                :key="index"
                style="display:inline-block;width:50%"
              >
                <div class="formRow leftLabel"><span>{{item.approvalPropertyName}}</span></div>
                <div
                  v-if="item.approvalPropertyType == '7'"
                  class="formRow rightLabel"
                >
                  <div
                    class="docContainer"
                    style="width:100%"
                  >
                    <div
                      class="docContent"
                      style="display:flex;width:auto"
                      v-for="(file,index) in item.approvalPropertyValue"
                      :key="index"
                    >
                      <img
                        src="@/assets/DOC.png"
                        style="width:40px;height:40px"
                      >
                      <div style="width:auto">
                        <el-tooltip
                          :content="file.name"
                          placement="top"
                        >
                          <span class="fileName">{{file.name}}</span>
                        </el-tooltip>
                        <el-button
                          v-show="/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(file.url)"
                          type="text"
                          @click="picPreview(file.url)"
                        >预览</el-button>
                        <a
                          v-show="!/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(file.url)"
                          style="color:#409EFF;font-size:14px;margin-right:10px;"
                          target='_blank'
                          :href="'http://view.officeapps.live.com/op/view.aspx?src='+file.url"
                        >预览</a>
                        <el-dialog :visible.sync="dialogVisible">
                          <img
                            width="100%"
                            :src="dialogImageUrl"
                            alt=""
                          >
                        </el-dialog>

                        <el-button
                          type="text"
                          @click="downLoadFile(file.url)"
                        >下载</el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.approvalPropertyType != '7'"
                  class="formRow rightLabel"
                ><span>{{item.approvalPropertyValue}}</span></div>
              </div>
              <div
                style="display:inline-block;width:50%;"
                v-if="applyValueList.length % 2 != 0"
              >
                <div class="formRow leftLabel"><span></span></div>
                <div class="formRow rightLabel"><span></span></div>
              </div>
            </div>
          </div>
          <div>
            <div class="formTitle"><label>审批记录</label></div>
            <div class="formWrap">
              <div
                v-for="(item,index) in approvalProcessList"
                :key="index"
              >
                <div class="formRow leftLabel recordLeft"><span>{{item.approvalStatusName}}</span></div>
                <div class="formRow rightLabel recordLabel">
                  <div><label>{{item.userName}}</label>&nbsp;&nbsp;<label>{{item.approvalStatusName}}</label> </div>
                  <div style="color: #797F8D;"><span v-if="item.approvalStatusName!=='发起'">审批意见:&nbsp;&nbsp;{{item.approvalSuggestion}}&nbsp;&nbsp;</span>{{item.time}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sign">
          <div class="signDIv">
            <span>签字：</span>
          </div>
          <div class="signDIv">
            <span>日期：</span>
          </div>
        </div>-->
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt />
		</el-dialog>
		<!-- 组织架构图 -->
		<el-dialog :visible.sync="dialogOrgVisible" fullscreen> <organizeMap isApproval isView isModal /> </el-dialog>
		<!-- 组织职能 -->
		<el-dialog
			:title="'组织职能'"
			top="30vh"
			width="900px"
			center
			:visible.sync="dialogOrgFunctionVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<baseTable :dataList="viewData" :columns="viewColumns" :showPagination="false" :spanMethod="spanMethod" v-loading="formLoading" />
		</el-dialog>
	</div>
</template>
<script>
import * as api from '@/api/audit';
import { isImageFileName } from '@/utils/util.js';
import organizeMap from '../../organizingpersonnel/organizing/organizational/organizeMap.vue';
import baseTable from '@/views/components/components-base-table';
export default {
	components: { organizeMap, baseTable },
	data() {
		return {
			tableData: [],
			tables: {
				basicValueList: {
					title: '基本信息',
					data: [],
					col: 4
				},
				applyValueList: {
					title: '申请详情',
					data: [],
					col: 4
				},
				approvalProcessList: {
					title: '审批记录',
					data: [],
					col: 2
				},
				ccList: {
					title: '抄送记录',
					data: [],
					col: 2
				}
			},
			id: '',
			title: '',
			basicValueList: [],
			approvalProcessList: [],
			applyValueList: [],
			dialogImageUrl: '',
			dialogVisible: false,
			dialogOrgVisible: false,
			dialogOrgFunctionVisible: false,
			formLoading: false,
			viewData: [],
			viewColumns: [
				{ label: '一级职能', key: 'functionDesc1' },
				{
					label: '二级职能',
					key: 'functionsTwo',
					render: (e) => {
						if ([2, 3].includes(e.functionLevel)) {
							return <span>{e.functionDesc}</span>;
						}
						return <div></div>;
					}
				},
				{
					label: '三级职能',
					key: 'functionsThree',
					children: [
						{
							label: '计划/规划',
							key: 'plan'
						},
						{
							label: '组织/实施',
							key: 'enforce'
						},
						{
							label: '协调/配合',
							key: 'cohere'
						},
						{
							label: '指导/监控',
							key: 'monitor'
						}
					]
				}
			]
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.id = this.$route.query.id;
			this.getDetail();
		});
	},
	methods: {
		isImageFileName,
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList;
			} catch (err) {
				return [];
			}
		},
		// 组织职能详情
		getViewDetail(orgId) {
			return this.$axios
				.post('/v1/org/findOrgFunctionTempByOrgId', { request: { orgId } })
				.then((res) => {
					if (res.code) {
						return;
					}

					return res;
				})
				.catch(() => {});
		},
		async handleView(data) {
			this.dialogOrgFunctionVisible = true;
			this.formLoading = true;
			const res = await this.getViewDetail(data.tempId);

			this.formLoading = false;
			this.viewData = res.orgFunctions?.map((val) => {
				const [plan, enforce, cohere, monitor] = [
					val.orgFunctions[0]?.functionDesc,
					val.orgFunctions[1]?.functionDesc,
					val.orgFunctions[2]?.functionDesc,
					val.orgFunctions[3]?.functionDesc
				];

				return { ...val, cohere, enforce, monitor, plan, functionDesc1: res.functionDesc };
			});
		},
		// eslint-disable-next-line no-unused-vars
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				if (rowIndex === 0 && columnIndex === 0) {
					return [this.viewData.length, 1];
				}
				return [0, 0];
			}
		},
		getDetail() {
			api.getApprovalDetail({ request: { id: this.id } }).then((res) => {
				this.title = res.title;
				this.basicValueList = res.basicValueList;
				this.applyValueList = res.applyValueList;
				this.approvalProcessList = res.approvalProcessList;
				// eslint-disable-next-line guard-for-in
				for (const key in this.tables) {
					const table = this.tables[key];

					if (table.col === 4) {
						if (res[key] && res[key].length % 2 !== 0) {
							res[key].push({});
						}
						// eslint-disable-next-line space-in-parens
						for (; res[key].length > 0; ) {
							this.tables[key].data.push(res[key].splice(0, 2));
						}
						if (key === 'applyValueList' && !res[key]?.length) {
							this.tables[key].orgRecords = res?.orgRecords || [];
							this.tables[key].subType = res?.subType;
						}
					} else {
						table.data = res[key];
					}
				}
				// for (let item of this.applyValueList) {
				//   if (item.approvalPropertyType == '7') {
				//     if (item.approvalPropertyValue != '') {
				//       item.approvalPropertyValue = JSON.parse(item.approvalPropertyValue);
				//     }
				//   }
				// }
				// console.log(this.applyValueList);
			});
		},
		downLoadFile(url) {
			window.open(url);
		},
		picPreview(url) {
			this.dialogImageUrl = url;
			this.dialogVisible = true;
		}
	}
};
</script>
<style lang="scss">
@import '../../../styles/print-table.scss';
.result-tables-wrapper {
	padding: 30px;
	.table-title {
		border-collapse: collapse;
		line-height: 50px;
		height: 50px;
		padding-bottom: 27px;
	}
	.changeView {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 14px;
		color: #409eff;
	}
	.table__print {
		width: 50%;
		margin: 0 auto;
	}
}
.result-tables-wrapper table thead {
	font-size: 18px;
	color: #2b2b2b;
	padding-bottom: 27px;
	line-height: 50px;
	height: 50px;
	border-collapse: collapse;
	border-color: #c5cdda;
}
.result-tables-wrapper table {
	td {
		padding: 15px !important;
	}
}
.result-tables-wrapper .property-name {
	width: 20%;
	background: #f9faff;
}
@media print {
	.result-tables-wrapper {
		background-color: #fff;
		height: 100%;
		padding: 0;
		.changeView {
			display: none;
		}
		.table__print {
			width: 100%;
		}
	}
	.media-print__hidden {
		display: none;
		visibility: hidden;
	}
}
</style>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
.inputDisable {
	background: #f5f7fa;
	color: #c0c4cc;
	cursor: not-allowed;
}
.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			min-height: 300px;
			padding: 50px;
		}
	}
}
.auditForm {
	border: 1px solid #dcdfe6;
}
.formTitle {
	text-align: center;
	margin-top: 10px;
	height: 40px;
	line-height: 40px;
}
.formWrap {
	margin-top: 10px;
	border-bottom: 1px solid #dcdfe6;
}
.formRow {
	display: inline-block;
	min-height: 60px;
	line-height: 60px;
	border-top: 1px solid #dcdfe6;
	vertical-align: text-top;
}
.leftLabel {
	width: 50%;
	text-align: center;
	background: #f9faff;
	border-right: 1px solid #dcdfe6;
	color: #777e8c;
}
.rightLabel {
	width: 50%;
	padding-left: 10px;
}
.recordLeft {
	width: 25%;
}
.recordLabel {
	width: 75%;
	line-height: 30px;
	padding-left: 20px;
}
p {
	margin-top: 0px;
}
.sign {
	float: right;
	margin-top: 20px;
}
.signDIv {
	width: 150px;
	display: inline-block;
	color: #2b3642;
}
.docContainer {
	.docContent {
		display: flex;
		align-items: center;
		margin-left: 10px;
		min-width: 120px;
	}
	.fileName {
		// display: block;
		font-size: 13px;
		text-align: left;
		// width: 300px;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
	}
}
</style>
